@use "../config/" as *;
.waf-squad {
    @extend %member-item;
    .title {
        @extend %title-40;
    }
    .waf-head {
        @extend %my-20;
    }
    .player {
        &-item-list {
            @include grid(2, var(--space-4));
            @extend %p-4;
            @extend %border-radius-l;
            @extend %bg-neutral-5;
            @extend %mb-6;
        }
        &-item {
            @extend %hidden;
        }
        &-info {
            flex-grow: 1;
            @extend %bg-neutral-0;
        }
        &-name{
            display: block;
            line-height: 1;
        }
        &-body{
            @extend %h-100;
            @extend %flex-column;
        }
    }
    .item-list-title {
        grid-area: 1/ span 2;
        @extend %title-40;
        @extend %flex-fs-c;
        @extend %gap-4;
        @extend %mb-6;
        @extend %uppercase;
    }
    .logo {
        width: 2.4rem;
        aspect-ratio: 1/1;
        flex-shrink: 0;
        @extend %flex;
        @extend %circle-radius;
    }
}
@include mq(col-lg) {
    .waf-squad {
        .title,
        .item-list-title {
            font-size: 4rem;
        }
        .player {
            &-item-list {
                padding: var(--space-20);
                grid-template-columns: repeat(4, 1fr);
                margin-bottom: var(--space-14);
            }
            &-thumbnail {
                aspect-ratio: 5/4;
            }
        }
        .item-list-title {
            grid-area: 1/span 4;
        }
        .logo {
            width: 3.2rem;
        }
    }
}
@include mq(col-desktop) {
    .waf-squad {
        .player {
            &-item-list {
                grid-template-columns: repeat(6, 1fr);
            }
        }
        .item-list-title {
            grid-area: 1/span 6;
        }
    }
}